import createVideo from './video';

/**
 * Show an element which was previously hidden.
 *
 * @param {HTMLElement} element
 * @return {void}
 */
function show(element) {
  element.removeAttribute('style');
}

/**
 * Visually hide an element.
 *
 * @param {HTMLElement} element
 * @return {void}
 */
function hide(element) {
  const style = {
    position: 'absolute',
    overflow: 'hidden',
    top: 0,
    left: 0,
    width: '1px',
    height: '1px',
    clip: 'rect(0 0 0 0)',
  };

  for (let key in style) {
    element.style[key] = style[key];
  }
}

/**
 * Insert an adjacent element after the other.
 *
 * @param {HTMLElement} element
 * @param {HTMLElement} other
 * @return {HTMLElement}
 */
function insertAfter(element, other) {
  return element.insertAdjacentElement('afterend', other);
}

/**
 * Factory function that replaces a DOM element with a lazy loaded
 * video element.
 *
 * @param {HTMLElement} element
 * @return {HTMLElement}
 */
export default function(element) {
  if (!element || !element.hasAttribute('data-video-url')) {
    return false;
  }

  const target = element.closest('picture') || element;
  const url = element.getAttribute('data-video-url');
  const video = createVideo(url, {
    className: ['o-video', 'u-fit', 'is-loading'].join(' '),
  });

  hide(video);
  insertAfter(target, video);

  video.addEventListener('loadeddata', () => {
    hide(element);
    show(video);
    video.classList.remove('is-loading');
    video.classList.add('is-playing');
    element.removeAttribute('data-video-url');
  });

  return video;
}
