/**
 * Load JavaScript files asynchronously:
 *
 * @param {string} src
 * @param {Function} cb
 * @return {HTMLScriptElement}
 */
export default function(src, cb) {
  const script = document.createElement('script');
  script.async = true;
  script.src = src;
  script.onload = cb;

  let element = document.getElementsByTagName('script')[0];
  element = element || document.body.lastElementChild;
  element.parentNode.insertBefore(script, element);

  return script;
}
