import Swiper from 'swiper';

import {
  Autoplay,
  Navigation
} from 'swiper/modules';

/**
 * Default settings for the carousel component.
 *
 * @type {Object}
 */
const defaults = {
  direction: 'horizontal',
  slides: 1,
  autoplay: false,
  loop: false
};

/**
 * Return the first element that matches the given class name.
 *
 * @param {string} className
 * @param {HTMLElement=} [context=document]
 * @return {?HTMLElement}
 */
function $(className, context = document) {
  return context.querySelector(className);
}

/**
 * Factory method to create new carousel component.
 *
 * @param {HTMLElement} container
 * @param {Object} [options={}]
 * @return {Object}
 */
export default function(container, options = {}) {

  /**
   * The swiper instance.
   *
   * @type {Swiper}
   */
  let carousel;

  /**
   * The element representing the carousel.
   *
   * @type {HTMLElement}
   */
  let element;

  /**
   * The button for the next slide.
   *
   * @type {HTMLButtonElement}
   */
  let nextButton;

  /**
   * The button for the previous slide.
   *
   * @type {HTMLButtonElement}
   */
  let prevButton;

  /**
   * Initialize the carousel component.
   *
   * @return {void}
   */
  function setup() {
    options = Object.assign({}, defaults, options);

    element = $('.swiper', container);
    nextButton = $('.swiper-button-next', container);
    prevButton = $('.swiper-button-prev', container);

    if (!element || !nextButton || !prevButton) {
      return;
    }

    carousel = new Swiper(element, {
      modules: [Autoplay, Navigation],
      slidesPerView: options.slides > 2 ? 1 : options.slides,
      direction: options.direction,
      loop: options.loop,
      autoplay: options.autoplay ? {
        delay: 5000,
        disableOnInteraction: true
      } : false,
      navigation: {
        prevEl: prevButton,
        nextEl: nextButton
      },
      breakpoints: options.slides > 2 ? {
        640: {
          slidesPerView: Math.min(2, options.slides),
        },
        980: {
          slidesPerView: Math.min(3, options.slides),
        },
        1248: {
          slidesPerView: Math.min(4, options.slides),
        }
      } : null,
    });
  }

  /**
   * Gracefully destroy the current carousel instance.
   *
   * @return {void}
   */
  function destroy() {
    if (carousel) {
      carousel.destroy();
    }
  }

  // Initialize the component if the given element exists
  if (container) {
    setup();
  }

  // Public API
  return {
    setup,
    destroy
  };
}
