/**
 * A reference to the slice method of the array prototype.
 *
 * @type {Function}
 */
const slice = Array.prototype.slice;

/**
 * A tiny wrapper for the querySelectorAll() method of the Element interface.
 * Returns all DOM elements that match the given selector.
 *
 * @param {string} selector
 * @param {Element} [context=document]
 * @return {Array<Element>}
 */
export default function(selector, context = document) {
  return slice.call(context.querySelectorAll(selector));
}
