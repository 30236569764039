import getJSON from './get-json';

/**
 * The storage interface to use as data cache.
 *
 * @type {Session}
 */
const storage = sessionStorage || null;

/**
 * Check if the given value is of type string.
 *
 * @param {*} value
 * @return {boolean}
 */
function isString(value) {
  return typeof value === 'string';
}

/**
 * Fetch a serialized object from the session storage.
 *
 * @param {string} key
 * @return {*}
 */
function getResponse(key) {
  const value = storage.getItem(key);
  return isString(value) ? JSON.parse(value) : value;
}

/**
 * Save a serialized representation of the given value in the session storage.
 *
 * @param {string} key
 * @param {*} value
 * @return {*}
 */
function storeResponse(key, value) {
  storage.setItem(key, isString(value) ? value : JSON.stringify(value));
  return value;
}

/**
 * A wrapper function for getJSON() that stores the JSON response in the session
 * storage of the client for subsequent requests.
 *
 * @param {string} url
 * @return {Promise}
 */
export default function cacheJSON(url) {
  if (!storage) {
    return getJSON(url);
  }

  const response = getResponse(url);

  return response
    ? new Promise(resolve => resolve(response))
    : getJSON(url).then(data => storeResponse(url, data));
}
