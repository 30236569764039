import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI from 'photoswipe/dist/photoswipe-ui-default';

/**
 * A reference to the slice method of the array prototype.
 *
 * @type {Function}
 */
const slice = Array.prototype.slice;

/**
 * Default settings for the gallery component.
 *
 * @type {Object}
 */
const defaults = {
  attrGalleryId: 'data-gallery-id',
  attrPhotoId: 'data-photo-id',
  attrImageWidth: 'data-image-width',
  attrImageHeight: 'data-image-height',
  classNameLink: 'js-gallery-link',
  classNameImage: 'js-gallery-image',
  classNameCaption: 'js-gallery-caption',
  opacity: 0.96,
  loop: true,
  counter: true,
  shareButtons: false,
};

/**
 * A global counter used to generate a unique identifier for each gallery
 * instantiated.
 *
 * @type {number}
 */
let uid = 0;

/**
 * Factory method to create new gallery component.
 *
 * @param {HTMLElement} container
 * @param {Object} [options={}]
 * @return {Object}
 */
export default function (container, options = {}) {

  /**
   * A collection of gallery items.
   *
   * @type {Array<Object>}
   */
  const items = [];

  /**
   * A unique identifier for the current component instance.
   *
   * @type {number}
   */
  const gid = ++uid;

  /**
   * Initialze the galler component.
   *
   * @return {void}
   */
  function setup() {
    options = Object.assign({}, defaults, options);

    const links = slice.call(container.getElementsByClassName(options.classNameLink));

    links.forEach((link, i) => {
      const image = link.querySelector(`.${options.classNameImage}`);

      setGalleryId(link, gid);
      setPhotoId(link, i);

      items.push({
        src: link.href,
        msrc: image.src,
        w: getImageWidth(link),
        h: getImageHeight(link),
        el: link,
      });
    });

    container.addEventListener('click', onClick);
  }

  function destroy() {
    container.removeEventListener('click', onClick);
  }

  /**
   * Retrieve a gallery item by its unique identifier.
   *
   * @param {number} pid
   * @return {?Object}
   */
  function getItem(pid) {
    return items[pid] || null;
  }

  /**
   * Retrieve a gallery image by its unique identifier.
   *
   * @param {number} pid
   * @return {?HTMLImageElement}
   */
  function getImage(pid) {
    const item = getItem(pid);

    if (item) {
      return item.el.querySelector('img');
    }

    return null;
  }

  /**
   * Get the size of an element and its absolute position in the document.
   *
   * @param {HTMLElement} element
   * @return {Object<string,number>}
   */
  function getBounds(element) {
    const scrollY = window.pageYOffset || document.documentElement.scrollTop;
    const rect = element.getBoundingClientRect();

    return {
      x: rect.left,
      y: rect.top + scrollY,
      w: rect.width,
      h: rect.height,
    };
  }

  /**
   * Retrieve the gallery id the given element belongs to.
   *
   * @param {HTMLElement} element
   * @return {number}
   */
  function getGalleryId(element) {
    return parseInt(element.getAttribute(options.attrGalleryId), 10);
  }

  /**
   * Assign a gallery id to the given element.
   *
   * @param {HTMLElement} element
   * @param {number} id
   * @return {void}
   */
  function setGalleryId(element, id) {
    element.setAttribute(options.attrGalleryId, '' + id);
  }

  /**
   * Get the unique identifier of the given gallery image.
   *
   * @param {HTMLElement} element
   * @return {number}
   */
  function getPhotoId(element) {
    return parseInt(element.getAttribute(options.attrPhotoId), 10);
  }

  /**
   * Assign a photo id to the given element.
   *
   * @param {HTMLElement} element
   * @param {number} pid
   * @return {void}
   */
  function setPhotoId(element, pid) {
    element.setAttribute(options.attrPhotoId, '' + pid);
  }

  /**
   * Extract the image width of the referenced gallery image.
   *
   * @param {HTMLElement} element
   * @return {number}
   */
  function getImageWidth(element) {
    return parseInt(element.getAttribute(options.attrImageWidth, 10));
  }

  /**
   * Extract the image height of the referenced gallery image.
   *
   * @param {HTMLElement} element
   * @return {number}
   */
  function getImageHeight(element) {
    return parseInt(element.getAttribute(options.attrImageHeight, 10));
  }

  /**
   * Determine whether the given element belongs to a specific gallery.
   *
   * @param {HTMLElement} element
   * @param {number} id
   * @return {boolean}
   */
  function belongsToGallery(element, id) {
    return getGalleryId(element) === id;
  }

  /**
   * Open an image gallery and show the image with given photo id.
   *
   * @param {?Number} [pid=null]
   * @return {PhotoSwipe}
   */
  function openGallery(pid = null) {
    const {
      counter,
      loop,
      opacity,
      shareButtons,
    } = options;

    const overlay = document.querySelector('.pswp');
    const gallery = new PhotoSwipe(overlay, PhotoSwipeUI, items, {
      index: pid || 0,
      galleryUID: gid,
      bgOpacity: opacity,
      loop: loop,
      counterEl: counter,
      shareEl: shareButtons,
      getThumbBoundsFn: index => getBounds(getImage(index)),
    });

    gallery.init();

    return gallery;
  }

  /**
   * Event handler for the click event.
   *
   * @param {Event} event
   * @return {void}
   */
  function onClick(event) {
    const target = event.target.closest('a');

    if (!target) {
      return;
    }

    const pid = getPhotoId(target);

    if (!belongsToGallery(target, gid)) {
      return;
    }

    openGallery(pid);
    event.preventDefault();
  }

  // Initialize the current component
  if (container) {
    setup();
  }

  // Public API
  return {
    setup,
    destroy,
    open: openGallery,
  };
}
