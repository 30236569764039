/**
 * A factory function used to generate a video element that can be used as
 * animated fullscreen background by clients supporting the corresponding
 * video codecs.
 *
 * @param {string|Array<string>} sources
 * @param {Object<string,*>} options
 * @return {HTMLElement}
 */
export default function(sources, options = {}) {
  sources = (typeof sources !== 'string') ? sources : [sources];

  const video = document.createElement('video');
  video.controls = false;
  video.loop = true;
  video.mute = true;
  video.autoplay = true;
  video.preload = 'auto';

  for (let key in options) {
    if (options.hasOwnProperty(key)) {
      video[key] = options[key];
    }
  }

  for (let source of sources) {
    const [,extension] = source.match(/\.([a-z0-9]+)$/i);
    const mime = `video/${extension}`;

    if (video.canPlayType(mime)) {
      const element = document.createElement('source');
      element.src = source;
      element.setAttribute('type', mime);
      video.appendChild(element);
    }
  }

  return video;
}
