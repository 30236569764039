/**
 * Load JSON-encoded data from the server using a GET HTTP request.
 *
 * @param {string} url
 * @return {Promise}
 */
export default function getJSON(url) {
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();

    request.open('GET', url, true);
    request.responseType = 'json';

    request.onload = () => {
      const status = request.status;

      if ((status >= 200) && (status < 400)) {
        resolve(request.response);
      } else {
        reject(status);
      }
    };

    request.onerror = () => {
      reject(request.status);
    };

    request.send();
  });
}
