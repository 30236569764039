import objectFitImages from 'object-fit-images';
import responsiveNav from 'responsive-nav';

import loadScript from './util/load-script';
import $$ from './util/query-all';

import carousel from './app/carousel';
import gallery from './app/gallery';
import map from './app/map';
import tabs from './app/tabs';
import video from './app/lazy-video';

// Allow the application to apply custom css when JavaScript is enabled
const root = document.documentElement;
root.classList.remove('no-js');
root.classList.add('js');

// Capture the time at which the script starts loading
const startTime = (new Date()).getTime();

// Perform feature detection and load polyfills if required
if (browserSupportsAllFeatures()) {
  main();
} else {
  loadScript('/assets/js/polyfills.js', main);
  root.classList.add('+polyfill');
}

// Lazy load picturefill if client is missing native support
if (!browserSupportsPictureElement()) {
  loadScript('/assets/js/picturefill.js');
  root.classList.add('-picture-element');
} else {
  root.classList.add('+picture-element');
}

// Determine support for object fit to fix dimensions of background
// video for older browsers
if (browserSupportsObjectFit()) {
  root.classList.add('+object-fit');
} else {
  root.classList.add('-object-fit');
}

/**
 * Determine whether the current browser supports all of the required features.
 *
 * @return {boolean}
 */
function browserSupportsAllFeatures() {
  return root.classList
    && typeof Promise === 'function'
    && typeof Object.assign === 'function'
    && typeof Element.prototype.closest === 'function';
}

/**
 * Determine whether the current browser supports the picture element.
 *
 * @return {boolean}
 */
function browserSupportsPictureElement() {
  return !!window.HTMLPictureElement;
}

/**
 * Determine whether the current browser supports the object-fit
 * property.
 *
 * @return {boolean}
 */
function browserSupportsObjectFit() {
  const dummy = (typeof Image === 'function') ? new Image() : {style: {}};
  return 'object-fit' in dummy.style;
}

/**
 * Get the width of the current viewport.
 *
 * @return {number}
 */
function viewportWidth() {
  return Math.max(root.clientWidth, window.innerWidth || 0);
}

/**
 * Begin the playback of the given video element.
 *
 * @param {HTMLMediaElement} element
 * @return {void}
 */
function playVideo(element) {
  element.play();
  element.classList.remove('is-paused');
  element.classList.add('is-playing');
}

/**
 * Pause the playback of the given video element.
 *
 * @param {HTMLMediaElement} element
 * @return {void}
 */
function pauseVideo(element) {
  element.classList.add('is-paused');
  element.classList.remove('is-playing');
  element.pause();
}

/**
 * Resume a video if it is paused, pause a video if it is playing.
 *
 * @param {HTMLMediaElement} element
 * @return {void}
 */
function togglePlayback(element) {
  if (element.paused) {
    playVideo(element);
  } else {
    pauseVideo(element);
  }
}

/**
 * Register an event handler triggered once an image has been loaded completely.
 *
 * @param {HTMLElement} element
 * @param {Function} cb
 * @return {Promise}
 */
function imageLoaded(element) {
  return new Promise(resolve => {
    if (element.complete) {
      resolve(element);
    } else {
      element.addEventListener('load', () => resolve(element));
    }
  });
}

/**
 * Load and setup the background video for large screens and fast network
 * connections.
 *
 * @param {HTMLImageElement} image
 * @return {void}
 */
function loadBackgroundVideo(image) {
  const now = (new Date()).getTime();
  const duration = (now - startTime) / 1000;

  if ((duration > 1) || (viewportWidth() < 980)) {
    return;
  }

  const container = image.closest('.c-masthead');
  const element = video(image);

  container.addEventListener('click', () => togglePlayback(element));
}

/**
 * Main entry point of the application.
 *
 * @return {void}
 */
function main() {
  responsiveNav('.js-responsive-menu', {
    customToggle: '.js-nav-toggle',
    navClass: 'c-menu--responsive',
    navActiveClass: 'is-nav-active',
    openPos: 'absolute',
  });

  objectFitImages('.u-fit');

  $$('.js-gallery').forEach(gallery);
  $$('.js-map').forEach(map);
  $$('.js-tabs').forEach(tabs);

  $$('img[data-video-url]').forEach(image => {
    imageLoaded(image).then(loadBackgroundVideo);
  });

  $$('.js-carousel').forEach(element => {
    carousel(element, {
      slides: parseInt(element.getAttribute('data-carousel-slides') || '1', 10),
      autoplay: element.hasAttribute('data-carousel-autoplay'),
    });
  });
}
