import imagesLoaded from 'imagesloaded';
import Swiper from 'swiper';

import {
  Navigation
} from 'swiper/modules';

/**
 * KeyCode of the left arrow key.
 *
 * @type {number}
 */
const ARROW_LEFT = 37;

/**
 * KeyCode of the right arrow key.
 *
 * @type {number}
 */
const ARROW_RIGHT = 39;

/**
 * A reference to the slice method of the array prototype.
 *
 * @type {Function}
 */
const slice = Array.prototype.slice;

/**
 * Default settings for the tab component.
 *
 * @type {Object}
 */
const defaults = {
  classNameList: 'js-tablist',
  classNameTab: 'js-tab',
  classNameActiveTab: 'is-active',
  classNamePanel: 'js-tabpanel'
};

/**
 * Factory method to create new tab component.
 *
 * @param {HTMLElement} container
 * @param {Object} [options={}]
 * @return {Object}
 */
export default function(container, options = {}) {

  /**
   * The swiper instance.
   *
   * @type {Swiper}
   */
  let carousel = null;

  /**
   * Reference to the node representing the tablist item.
   *
   * @type {HTMLElement}
   */
  let list = null;

  /**
   * Array of nodes representing the tablist items.
   *
   * @type {HTMLElement}
   */
  let items = null;

  /**
   * Array of nodes representing the individual tab items.
   *
   * @type {HTMLElement}
   */
  let tabs = null;

  /**
   * Array of nodes representing the individual tab panels.
   *
   * @type {HTMLElement}
   */
  let panels = null;

  /**
   * Index of the currently active tab element.
   *
   * @type {number}
   */
  let currentIndex = 0;

  /**
   * Initialize the tab component.
   *
   * @return {void}
   */
  function setup() {
    options = Object.assign({}, defaults, options);

    const {
      classNameList,
      classNameTab,
      classNamePanel,
    } = options;

    list = container.getElementsByClassName(classNameList)[0];
    items = slice.call(list.children);
    tabs = slice.call(container.getElementsByClassName(classNameTab));
    panels = slice.call(container.getElementsByClassName(classNamePanel));

    list.setAttribute('role', 'tablist');
    items.forEach(item => item.setAttribute('role', 'presentation'));

    panels.forEach(panel => {
      panel.setAttribute('role', 'tabpanel');
      panel.hidden = true;
    });

    tabs.forEach(tab => {
      tab.setAttribute('role', 'tab');
      tab.setAttribute('tabindex', '-1');
      tab.setAttribute('aria-controls', tab.getAttribute('href').substring(1));
    });

    const tab = getTabById(location.hash);
    const index = getTabIndex(tab);

    if (index >= 0) {
      currentIndex = index;
    }

    setActiveTab(currentIndex);
    imagesLoaded(container, onImagesLoaded);

    list.addEventListener('click', onClick);
    list.addEventListener('keydown', onKeydown);
    window.addEventListener('hashchange', onHashChange);
  }

  /**
   * Enable scrolling for the given tablist.
   *
   * @return {void}
   */
  function createCarousel() {
    const tablist = container.querySelector('.swiper');
    const nextButton = container.querySelector('.swiper-button-next');
    const prevButton = container.querySelector('.swiper-button-prev');

    return new Swiper(tablist, {
      modules: [Navigation],
      direction: 'horizontal',
      loop: false,
      slidesPerView: 'auto',
      spaceBetween: 32,
      navigation: {
        nextEl: nextButton,
        prevEl: prevButton
      }
    });
  }

  /**
   * Gracefully destroy the current tab component instance.
   *
   * @return {void}
   */
  function destroy() {
    if (carousel) {
      carousel.destroy();
    }

    list.removeEventListener('click', onClick);
    list.removeEventListener('keydown', onKeydown);
  }

  /**
   * Mark an element as the currently selected.
   *
   * @param {HTMLElement} element
   * @return {void}
   */
  function selectElement(element) {
    const {
      classNameActiveTab,
    } = options;

    element.setAttribute('tabindex', '0');
    element.setAttribute('aria-selected', 'true');

    if (classNameActiveTab) {
      element.classList.add(classNameActiveTab);
      element.parentNode.classList.add(classNameActiveTab);
    }

    showControlledElement(element);
  }

  /**
   * Remove all attributes that mark an element as the currently selected.
   *
   * @param {HTMLElement} element
   * @return {void}
   */
  function clearElement(element) {
    const {
      classNameActiveTab,
    } = options;

    element.setAttribute('tabindex', '-1');
    element.removeAttribute('aria-selected');

    if (classNameActiveTab) {
      element.classList.remove(classNameActiveTab);
      element.parentNode.classList.remove(classNameActiveTab);
    }

    hideControlledElement(element);
  }

  /**
   * Show the element that is controlled by the given element.
   *
   * @param {HTMLElement} element
   * @return {HTMLElement}
   */
  function showControlledElement(element) {
    const relative = element.ownerDocument.getElementById(
      element.getAttribute('aria-controls')
    );

    if (relative) {
      relative.hidden = false;
      relative.children.forEach((child) => child.setAttribute('tabindex', '0'));
    }

    return relative;
  }

  /**
   * Hide the element that is controlled by the given element.
   *
   * @param {HTMLElement} element
   * @return {HTMLElement}
   */
  function hideControlledElement(element) {
    const relative = element.ownerDocument.getElementById(
      element.getAttribute('aria-controls')
    );

    if (relative) {
      relative.hidden = true;
      relative.children.forEach((child) => child.removeAttribute('tabindex'));
    }

    return relative;
  }

  /**
   * Change the currently active tab.
   *
   * @param {number} index
   * @param {boolean} focusTab
   * @return {void}
   */
  function setActiveTab(index, focusTab = false) {
    // Blur the current selection to avoid the selection of multiple tabs
    tabs
      .filter(element => element.hasAttribute('aria-selected'))
      .forEach(element => clearElement(element));

    // Update the pointer to the currently active tab, to allow navigation
    // via the keyboard
    currentIndex = index;

    // Highlight (and focus) the new selection
    const currentTab = tabs[currentIndex];
    selectElement(currentTab);

    if (focusTab) {
      currentTab.focus();

      if (carousel) {
        carousel.slideTo(index);
      }
    }
  }

  /**
   * Get the index at which the given tab element can be found in the array.
   *
   * @param {HTMLElement} tab
   * @return {number}
   */
  function getTabIndex(tab) {
    return tabs.indexOf(tab);
  }

  /**
   * Find a tab by a given identifier.
   *
   * @param {string} id
   * @return {?HTMLElement}
   */
  function getTabById(id) {
    return tabs.filter(element => element.getAttribute('href') === id).shift();
  }

  /**
   * Select the previous tab in the hierarchy.
   *
   * @return {void}
   */
  function prevTab() {
    const index = (currentIndex - 1);
    setActiveTab(index >= 0 ? index : tabs.length - 1, true);
  }

  /**
   * Select the next tab in the hierarchy.
   *
   * @return {void}
   */
  function nextTab() {
    const index = (currentIndex + 1) % tabs.length;
    setActiveTab(index, true);
  }

  /**
   * Event handler for the click event.
   *
   * @param {Event} event
   * @return {void}
   */
  function onClick(event) {
    const {
      classNameTab,
    } = options;

    const tab = event.target.closest(`.${classNameTab}`);

    if (!tab) {
      return;
    }

    const index = getTabIndex(tab);

    if (index < 0) {
      return;
    }

    setActiveTab(index);
    event.preventDefault();
  }

  /**
   * Event handler for the keydown event.
   *
   * @param {Event} event
   * @return {void}
   */
  function onKeydown(event) {
    const {
      classNameTab,
    } = options;

    const tab = event.target.closest(`.${classNameTab}`);

    if (!tab) {
      return;
    }

    switch (event.keyCode) {
      case ARROW_LEFT:
        prevTab();
        break;
      case ARROW_RIGHT:
        nextTab();
        break;
    }
  }

  /**
   * Event handler triggered when all images of the current container are loaded.
   *
   * @param {Event} event
   * @return {void}
   */
  function onImagesLoaded() {
    const frameWidth = list.parentElement.offsetWidth;
    const itemWidth = items.reduce((width, item) => width + item.offsetWidth, 0);

    const prevButton = container.querySelector('.swiper-button-prev');
    const nextButton = container.querySelector('.swiper-button-next');

    if (itemWidth > frameWidth) {
      list.classList.add('is-scrollable');
      carousel = createCarousel();
    } else {
      list.classList.add('is-static');
      prevButton.disabled = true;
      nextButton.disabled = true;
    }
  }

  /**
   * Event handler for the hashchange event.
   *
   * @param {Event} event
   * @return {void}
   */
  function onHashChange() {
    const tab = getTabById(location.hash);
    const index = getTabIndex(tab);

    if (index >= 0) {
      setActiveTab(index);
    }
  }

  // Initialize the current component
  if (container) {
    setup();
  }

  // Public API
  return {
    setup,
    destroy,
    select: setActiveTab,
    next: nextTab,
    prev: prevTab,
  };

}
