import loadScript from '../util/load-script';
import loadStyle from '../util/load-style';
import cacheJSON from '../util/cache-json';

/*global mapboxgl*/

/**
 * GEO coordinates of the city of Bremen.
 *
 * @type {Array<number>}
 */
const BREMEN = [8.8016937, 53.0792962];

/**
 * Size of the marker icon.
 *
 * @type {number}
 */
const ICON_SIZE = 48;

/**
 * The amount of padding in pixels to add to all calculated bounds.
 *
 * @type {number}
 */
const BOUNDS_PADDING = 128;

/**
 * Icon element used by default for map markers.
 *
 * @type {HTMLElement}
 */
const icon = (() => {
  const element = document.createElement('div');
  element.className = 'mapboxgl-marker';
  element.style.backgroundImage = 'url(/assets/images/marker.svg)';
  element.style.backgroundSize = 'cover';
  element.style.width = `${ICON_SIZE}px`;
  element.style.height = `${ICON_SIZE}px`;
  return element;
})();

/**
 * Default settings for the map component.
 *
 * @type {Object}
 */
const defaults = {
  accessToken: '',
  center: BREMEN,
  zoom: 14,
  icon: icon,
  scrollZoom: false,
  style: 'mapbox://styles/mapbox/dark-v9',
  userPosition: true,
};

/**
 * Factory method that creates an interactive map using the Mapbox Gl API.
 *
 * @param {HTMLElement} container
 * @param {Object=} [options={}]
 * @return {void}
 */
export default function(container, options = {}) {

  /**
   * The map instance.
   *
   * @type {mapboxgl.Map}
   */
  let map = null;

  /**
   * Initialize the map component.
   *
   * @return {void}
   */
  function setup() {
    options = Object.assign({}, defaults, options);

    const {
      accessToken,
      center,
      zoom,
      style,
      scrollZoom,
      userPosition,
    } = options;

    const authenticate = () => {
      mapboxgl.accessToken = accessToken || container.getAttribute('data-map-token');
    };

    const localize = () => {
      const lang = document.documentElement.getAttribute('lang') || 'en';
      map.setLayoutProperty('country-label-lg', 'text-field', `{name_${lang}}`);
    };

    const success = () => {
      container.classList.remove('is-loading');
      container.classList.add('is-loaded');
      localize();
      populate();
      return true;
    };

    const error = () => {
      container.classList.remove('is-loading');
      container.classList.add('has-error');
      return false;
    };

    const factory = () => {
      const instance = new mapboxgl.Map({
        container: container,
        style: style,
        center: center,
        zoom: zoom,
        scrollZoom: scrollZoom,
        failIfMajorPerformanceCaveat: true,
      });

      if (userPosition) {
        instance.addControl(new mapboxgl.GeolocateControl());
      }

      if (!scrollZoom) {
        instance.addControl(new mapboxgl.NavigationControl(), 'top-left');
      }

      instance.once('load', success);
      instance.once('error', error);

      return instance;
    };

    const populate = () => {
      const url = container.getAttribute('data-map-endpoint');

      if (url) {
        cacheJSON(url).then(items => fitBounds(addMarkers(items)));
      }
    };

    if (typeof mapboxgl === 'undefined' || !mapboxgl.supported()) {
      error();
    } else {
      authenticate();
      map = factory();
    }
  }

  /**
   * Pans and zooms the map to contain its visible area within the geographical
   * bounds containing all of the given map markers.
   *
   * @param {Array<mapboxgl.Marker>} markers
   * @param {number=} padding
   * @return {mapboxgl.LngLatBounds}
   */
  function fitBounds(markers, padding = BOUNDS_PADDING) {
    const bounds = new mapboxgl.LngLatBounds();

    if (markers.length > 0) {
      markers.forEach(marker => bounds.extend(marker.getLngLat()));
      map.fitBounds(bounds, {padding: padding});
    }

    return bounds;
  }

  /**
   * Add a collection of markers to the given map instance.
   *
   * @param {Array<Object>} items
   * @return {Array<mapboxgl.Marker>}
   */
  function addMarkers(items) {
    return items.map((item) => {
      const {
        lng,
        lat,
      } = item.geolocation;

      const marker = new mapboxgl.Marker(icon.cloneNode(), {
        offset: [-ICON_SIZE/2, -ICON_SIZE/2],
      });

      const popup = new mapboxgl.Popup({
        offset: [0, -ICON_SIZE/2],
        anchor: 'bottom',
      });

      popup.setHTML(item.summary || '');

      return marker
        .setLngLat([lng, lat])
        .setPopup(popup)
        .addTo(map);
    });
  }

  // Initialize component and lazy load resources. Removes fallback content.
  if (container) {
    container.classList.add('is-loading');

    loadScript('https://api.mapbox.com/mapbox-gl-js/v0.36.0/mapbox-gl.js', setup);
    loadStyle('https://api.mapbox.com/mapbox-gl-js/v0.36.0/mapbox-gl.css');
  }

  // Public API
  return {
    setup,
    add: (items, zoom) => {
      const markers = addMarkers(items);

      if (zoom) {
        fitBounds(markers);
      }
    },
  };
}
