/**
 * Load CSS files asynchronously:
 *
 * @param {string} href
 * @return {HTMLLinkElement}
 */
export default function(href) {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = href;
  link.media = 'only x';

  let element = document.querySelector('link[rel="stylesheet"]');
  element = element || document.head.lastChild;
  element.insertAdjacentElement('afterend', link);

  setTimeout(() => {
    link.media = 'all';
  });

  return link;
}
